
module.exports =
  metaInfo:
    title: 'Эффектная экономия на одежде и обуви'
    meta: [
      { name: 'description', content: 'Как экономить на одежде и выглядеть на миллион? Эксклюзивные советы, проверенные на практике сотнями бережливых модниц. Только на ХаниМани!' }
      { name: 'keywords', content: 'экономить на одежде, экономить на обуви, экономить на детской одежде' }
      { name: 'human_title', content: 'Эффектная экономия на одежде и обуви' }
      { name: 'human_subtitle', content: 'Как выглядеть хорошо без ущерба для кошелька' }
    ]
